/* eslint-disable */
import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  FormInput, FormLabel, withFormGroup
} from 'components';

class ExtraTravellerDetailsFieldset extends PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { title, travellerNumber, traveller, lastName, travellerTitle } = this.props;
    const Input = withFormGroup(FormInput);

    const PassportDateOfIssue = moment(traveller.passport_date_of_issue);
    const PassportDateOfIssueDay = PassportDateOfIssue ? PassportDateOfIssue.format('DD') : '';
    const PassportDateOfIssueMonth = PassportDateOfIssue ? PassportDateOfIssue.format('MM') : '';
    const PassportDateOfIssueYear = PassportDateOfIssue ? PassportDateOfIssue.format('YYYY') : '';

    const PassportExpiry = moment(traveller.passport_date_of_expiry);
    const PassportExpiryDay = PassportExpiry ? PassportExpiry.format('DD') : '';
    const PassportExpiryMonth = PassportExpiry ? PassportExpiry.format('MM') : '';
    const PassportExpiryYear = PassportExpiry ? PassportExpiry.format('YYYY') : '';
    return (
      <fieldset className="l-traveller-details-fieldset">
        <legend className="c-heading c-heading--h2">{`${travellerTitle} ${lastName}` || title || `Traveller #${travellerNumber}`}</legend>
        <hr />
        <br />
        <legend className="c-heading c-heading--h4">Passport details</legend>
        <br />
        <div className="l-form__contents">
          <Input
            id={`Traveller[${travellerNumber}]PassportNumber`}
            name="TravellerPassportNumber"
            label="Passport Number &#42;"
            defaultValue={traveller.passport_number}
            required
            placeholder="Passport Number"
          /> 
          <div className="l-form-group l-form-group--dob">
            <FormLabel inputID="PassportIssueDay" title="Date of Issue &#42;" />
            <FormInput
              id={`Traveller[${travellerNumber}]PassportIssueDay`}
              name="TravellerPassportIssueDay"
              type="number"
              placeholder="DD"
              defaultValue={PassportDateOfIssueDay || ''}
              required
              attrs={
                {
                  min: 1,
                  max: 31,
                }
              }
            />
            <FormInput
              id={`Traveller[${travellerNumber}]PassportIssueMonth`}
              name="TravellerPassportIssueMonth"
              type="number"
              placeholder="MM"
              defaultValue={PassportDateOfIssueMonth || ''}
              required
              attrs={
                {
                  min: 1,
                  max: 12,
                }
              }
            />
            <FormInput
              id={`Traveller[${travellerNumber}]PassportIssueYear`}
              name="TravellerPassportIssueYear"
              type="number"
              placeholder="YYYY"
              defaultValue={PassportDateOfIssueYear || ''}
              required
            />
          </div>
          <div className="l-form-group l-form-group--dob">
            <FormLabel inputID="PassportExpiryDay" title="Date of expiry &#42;" />
            <FormInput
              id={`Traveller[${travellerNumber}]PassportExpiryDay`}
              name="TravellerPassportExpiryDay"
              type="number"
              placeholder="DD"
              defaultValue={PassportExpiryDay || ''}
              required
              attrs={
                {
                  min: 1,
                  max: 31,
                }
              }
            />
            <FormInput
              id={`Traveller[${travellerNumber}]PassportExpiryMonth`}
              name="TravellerPassportExpiryMonth"
              type="number"
              placeholder="MM"
              defaultValue={PassportExpiryMonth || ''}
              required
              attrs={
                {
                  min: 1,
                  max: 12,
                }
              }
            />
            <FormInput
              id={`Traveller[${travellerNumber}]PassportExpiryYear`}
              name="TravellerPassportExpiryYear"
              type="number"
              placeholder="YYYY"
              defaultValue={PassportExpiryYear || ''}
              required
            />
          </div>
          <br/>
          <legend className="c-heading c-heading--h4">Emergency Contact</legend>
          <br />
          <Input
            id={`Traveller[${travellerNumber}]EmergencyContactName`}
            name="EmergencyContactName"
            label="Name &#42;"
            defaultValue={traveller.emergency_contact_name}
            required
            placeholder="Full name"
          />
          <Input
            id={`Traveller[${travellerNumber}]EmergencyContactRelationship`}
            name="EmergencyContactRelationship"
            label="Relationship &#42;"
            defaultValue={traveller.emergency_contact_relationship}
            required
            placeholder="Relationship"
          />     
          <Input
            id={`Traveller[${travellerNumber}]EmergencyContactNumber`}
            name="EmergencyContactNumber"
            label="Contact number &#42;"
            defaultValue={traveller.emergency_contact_number}
            required
            placeholder="Contact Number"
          />

          <br/>
          <legend className="c-heading c-heading--h4">Insurance Details</legend>
          <br />
          <Input
            id={`Traveller[${travellerNumber}]InsuranceName`}
            name="InsuranceName"
            label="Name of Insurer &#42;"
            defaultValue={traveller.insurance_name}
            required
            placeholder="Name of Insurer"
          />
          <Input
            id={`Traveller[${travellerNumber}]InsurancePolicyNumber`}
            name="InsurancePolicyNumber"
            label="Policy Number &#42;"
            defaultValue={traveller.insurance_policy_number}
            required
            placeholder="Policy Number"
          />
          <Input
            id={`Traveller[${travellerNumber}]InsuranceContactNumber`}
            name="InsuranceContactNumber"
            label="Phone number &#42;"
            defaultValue={traveller.insurance_contact_number}
            required
            placeholder="24 Hours Emergency Phone Number"
          />
          <br/>
          <Input
            id={`Traveller[${travellerNumber}]MedicalConditions`}
            name="TravellerMedicalConditions"
            label="Medical Conditions"
            defaultValue={traveller.medical_condition}
            placeholder="Medical Conditions"
          /> 
          <Input
            id={`Traveller[${travellerNumber}]DietaryRequirements`}
            name="TravellerDietaryRequirements"
            label="Dietary Requirements"
            defaultValue={traveller.allergies}
            placeholder="Dietary Requirements"
          /> 
        </div>
      </fieldset>
    );
  }
}

ExtraTravellerDetailsFieldset.propTypes = {
  traveller: PropTypes.any.isRequired,
  title: PropTypes.string,
  lastName: PropTypes.string,
  travellerTitle: PropTypes.string,
  travellerNumber: PropTypes.number,
};

ExtraTravellerDetailsFieldset.defaultProps = {
  title: '',
  lastName: '',
  travellerTitle: '',
  travellerNumber: 1,
};

export default ExtraTravellerDetailsFieldset;