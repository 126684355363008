import { REQUEST, RESPONSE_HANDLER } from 'utilities';

const BOOKING_REQUIRED_DATA = {
  complete: false,
  can_pay_partially: false,
  snow: false,
  mtb: false,
  locked: false,
  confirmed: false,
  agree_with_the_conditions: false,
  archived: false,
  new_booking: true,
  travellers: [{}],
};

/**
 * Create a booking
 * @param data
 * @returns {Promise<*>}
 * @constructor
 */
export const CREATE_BOOKING = async (dispatch, token, email, data) => {
  const CREATE = await REQUEST.post('bookings/bookings', {
    headers: {
      token,
      email,
    },
    json: {
      'bookings/booking': {
        ...BOOKING_REQUIRED_DATA,
        ...data,
      },
    },
  });

  const RESPONSE = await RESPONSE_HANDLER(CREATE);
  if (RESPONSE.success) {
    dispatch({
      type: 'USER_ADD_BOOKING',
      payload: RESPONSE.data,
    });
  }

  return RESPONSE;
};

/**
 * Get users bookings
 * @param dispatch
 * @param token
 * @param email
 * @returns {Promise<void>}
 * @constructor
 */
export const GET_BOOKINGS = async (dispatch, token, email) => {
  const GET = await REQUEST.get('bookings/bookings', {
    headers: {
      token,
      email,
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    if (RESPONSE.data['bookings/bookings'].length == 0) {
      return;
    }
    const NORMALIZE_DATA = {
      bookings: RESPONSE.data['bookings/bookings'],
      travellers: RESPONSE.data['bookings/travellers'],
    };
    // save to user store
    await dispatch({
      type: 'USER_SET_BOOKINGS',
      payload: NORMALIZE_DATA,
    });
    // overwrite raw response data
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

/**
 * Get all details for a booking
 * @param bookingID
 * @param token
 * @param email
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_BOOKING = async (dispatch, bookingID, token, email) => {
  const GET = await REQUEST.get(`bookings/bookings/${bookingID}`, {
    headers: {
      token,
      email,
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const NORMALIZE_DATA = {
      currentBooking: RESPONSE.data['bookings/booking'],
      travellers: RESPONSE.data['bookings/travellers'],
      accommodations: RESPONSE.data['bookings/accommodations'],
      adHocItems: RESPONSE.data['bookings/ad_hoc_items'],
      paymentCards: RESPONSE.data['bookings/payment_cards'],
      payments: RESPONSE.data['bookings/payments'],
      upgradeAccommodations: RESPONSE.data['bookings/upgrade_accommodations'],
      addOns: RESPONSE.data['bookings/addons'],
    };
    // save to booking store
    await dispatch({
      type: 'BOOKINGS_SET_ALL',
      payload: NORMALIZE_DATA,
    });
    // overwrite raw response data
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

/**
 * Update a booking
 * @param bookingID
 * @param token
 * @param email
 * @param data
 * @returns {Promise<*>}
 * @constructor
 */
export const UPDATE_BOOKING = async (bookingID, token, email, data) => {
  const UPDATE = await REQUEST.put(`bookings/bookings/${bookingID}`, {
    headers: {
      token,
      email,
    },
    json: {
      'bookings/booking': {
        ...data,
      },
    },
  });
  return RESPONSE_HANDLER(UPDATE);
};

/**
 * Get accommodation options for a booking - before OR after tour
 * @param bookingID
 * @param token
 * @param email
 * @param type
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_BOOKING_ACCOM = async (bookingID, token, email, type) => {
  // const GET = await REQUEST(`addons/accommodations/${accomID}`);
  const TYPE_URL = type === 'before' ? 'generate_pre_accommodation_options' : 'generate_post_accommodation_options';
  const GET = await REQUEST(`bookings/bookings/${bookingID}/${TYPE_URL}`, {
    headers: {
      token,
      email,
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    RESPONSE.data = RESPONSE.data.accommodations;
  }
  return RESPONSE;
};

/**
 * Get booking accomm upgrades
 * @param bookingID
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_ACCOMM_UPGRADES = async (bookingID, token, email) => {
  const GET = await REQUEST(`bookings/bookings/${bookingID}/generate_upgrade_accommodation_options`, {
    headers: {
      token,
      email,
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    RESPONSE.data = RESPONSE.data.accommodations;
  }
  return RESPONSE;
};

/**
 * Get addOn
 * @param addOnID
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_ADDON = async (addOnID) => {
  const GET = await REQUEST(`addons/addons/${addOnID}`);
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const NORMALIZE_DATA = {
      addon: RESPONSE.data['addons/addon'],
      options: RESPONSE.data['addons/options'],
      pictures: RESPONSE.data.pictures,
    };
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

export const CHECKING_AVAILABILITY = async (
  tour,
  departure,
) => {
  const CREATE_OR_CHECKING_DEPARTURE = await REQUEST.post('tour_info/departures/checking_departure', {
    json: {
      tour_id: tour,
      departure_date: departure,
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(CREATE_OR_CHECKING_DEPARTURE);
  return RESPONSE;
};

/**
 * Delete a booking
 * @param bookingID
 * @param token
 * @param email
 * @returns {Promise<*>}
 * @constructor
 */
export const DELETE_BOOKING = async (bookingID, token, email) => {
  const DELETE = await REQUEST.delete(`bookings/bookings/${bookingID}`, {
    headers: {
      token,
      email,
    },
  });
  return RESPONSE_HANDLER(DELETE);
};
